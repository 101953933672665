import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_TransactionSuccess } from "api-services/Cart/TransactionSuccess";
import { getCurrencySymbol } from "helpers/helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function Success() {
  // First fetch information of TransactionID, show incorrect Error if transactionID is wrong
  // If Transaction ID is correct, mark it Processed
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  // Access the query string parameters using the get method
  const transactionID = queryString.get("transaction");
  const isReferrerMatched =
    document.referrer === "https://checkout.stripe.com/";
  const [isLoading, setIsLoading] = useState(false);
  const [transactionResult, setTransactionResult] = useState([]);
  const [userObj, setUserObj] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    async function checkTransactionID() {
      setIsLoading(true);
      //debugger;
      var result = await API_TransactionSuccess({
        Id: transactionID,
        type: "success",
      });
      if (result.success) {
        //debugger;
        let ele = document.createElement("script");
        ele.src = "https://html2canvas.hertzen.com/dist/html2canvas.min.js";
        document.getElementsByTagName("body")[0].append(ele);

        setTransactionResult(result?.result?.transactions);
        setUserObj(result?.result?.user);
        let total = 0;
        result?.result?.transactions.map((transaction) => {
          total += transaction?.discountApplied
            ? transaction.discountedPrice
            : transaction.amount;
        });
        setTotalAmount(total);
      }
      setIsLoading(false);
    }
    console.log("Referrer::", document.referrer);
    if (
      isReferrerMatched ||
      process.env.REACT_APP_ENVIRONMENT === "Development"
    ) {
      checkTransactionID();
    }
  }, []);
  window.html2canvas = {};
  const convertCanvasToImage = (canvas) => {
    let image = new Image();
    image.src = canvas.toDataURL();
    return image;
  };

  const handlePrint = () => {
    window.html2canvas(document.querySelector("#divToPrint")).then((canvas) => {
      const printableDiv = document.getElementById("divToPrintChild");
      debugger;

      printableDiv.appendChild(convertCanvasToImage(canvas));
      setTimeout(() => {
        if (printableDiv) {
          const printWindow = window.open("", "_blank");

          printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
               <style>
               @media print {
                #divToPrint img {
                  width: 100%;
                }
               }
               </style>
                
              </head>
              <body>
                <div id="divToPrint" >${printableDiv.innerHTML}</div>
              </body>
            </html>
          `);
          printWindow.document.close();
          printWindow.focus();
          printWindow.print();
          printWindow.close();
        }
      }, 1500);
    });
  };

  return (
    <>
      {transactionResult.length > 0 && (
        <>
          <iframe
            src={`https://notaryweb3.ositracker.com/sales/salejs/amount:${totalAmount}/transaction:${transactionResult[0].stripeSessionID}`}
            alt=""
            width="50"
            height="50"
            border="0"
            frameborder="0"
          ></iframe>
        </>
      )}
      <div className="contain-box">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="container-fluid">
                <div className="row">
                  <div className="container">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          {!isLoading && transactionResult.length > 0 && (
                            <div className="col-md-12 text-right">
                              <Link
                                to="/checkout/next-steps"
                                className="btn btn-sm btn-outline-primary float-right"
                              >
                                Go to Next Steps{" "}
                                <FontAwesomeIcon icon={faArrowRight} />
                              </Link>
                            </div>
                          )}
                          <div className="col-md-12 mt-1 text-center">
                            {isLoading && (
                              <>
                                <Spinner></Spinner>
                              </>
                            )}
                            {!isLoading && transactionResult.length > 0 && (
                              <>
                                <div
                                  id="divToPrintChild"
                                  style={{ display: "none" }}
                                ></div>
                                <div className="receipt" id="divToPrint">
                                  <div
                                    style={{ color: "white" }}
                                    className="receipt-head p-sm-4 p-3"
                                  >
                                    <div className="d-flex justify-content-between align-items-center">
                                      <img
                                        src="/images/email/logo-trans.png"
                                        alt="Logo"
                                        height={30}
                                      />
                                      <h2
                                        className="times"
                                        style={{ float: "right" }}
                                      >
                                        Receipt
                                      </h2>
                                    </div>

                                    <div className="row my-4">
                                      <div className="col-xl-4 col-6 h-links text-xl-left text-center">
                                        <a
                                          style={{ color: "white" }}
                                          href="mailto:support@notaryweb3.com"
                                        >
                                          <i className="fa fa-envelope mr-md-2 mr-0" />
                                          support@notaryweb3.com
                                        </a>
                                      </div>

                                      <div className="col-md-4 col-6 mt-xl-0 mt-3 mx-auto">
                                        <div className="row justify-content-between">
                                          <div className="col-6">
                                            <p>Date:</p>
                                          </div>
                                          <div className="col-6">
                                            <p>
                                              {moment(
                                                transactionResult[0]?.ModifiedOn
                                              ).format("MM-DD-YYYY")}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row  h-links">
                                      <div className="col-2 text-left ml-2">
                                        <p>Receipt #:</p>
                                      </div>
                                      <div className="col-6">
                                        <p>
                                          {transactionResult[0]?.transactionID}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="receipt-body bg-white">
                                    <div className="receipt-info pb-5 pt-4 px-md-4 px-3">
                                      <div className="row">
                                        <div className="col-6 text-left">
                                          <h6 className="font-weight-bold mb-4">
                                            Invoice to :
                                          </h6>
                                          <h3 className="invoice_to font-weight-bold">
                                            {userObj.billing_Name ||
                                              userObj.name}
                                          </h3>
                                          <p className="address">
                                            {userObj.email} <br />
                                            {userObj.billing_Country === "US"
                                              ? "United States"
                                              : userObj.billing_Country}
                                          </p>
                                        </div>
                                        <div className="col-6">
                                          <h6 className="font-weight-bold mb-4 text-right">
                                            Paid to :
                                          </h6>
                                          <h5 className="paid_to font-weight-bold text-right">
                                            Notary Ventures, LLC
                                          </h5>
                                          <p className="address text-right">
                                            3375 S Rainbow Blvd #81710 <br />
                                            Las Vegas, NV, 89180
                                            <br />
                                            United States
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="receipt-prices">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th className="w-100">
                                              <h6 className="mb-0 font-weight-bold py-2 px-md-4 px-2 mr-auto">
                                                Domain Name
                                              </h6>
                                            </th>
                                            <th>
                                              <h6 className="mb-0 font-weight-bold py-2 px-md-4 px-2">
                                                Total
                                              </h6>
                                            </th>
                                            <th>
                                              <h6 className="mb-0 font-weight-bold py-2 px-md-4 px-2">
                                                Price
                                              </h6>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody className="text-light">
                                          {transactionResult.map(
                                            (transaction, index) => (
                                              <tr key={index}>
                                                <td className="py-3 mb-0">
                                                  {transaction.itemName}
                                                </td>
                                                <td className="py-3 mb-0 text-center">
                                                  {getCurrencySymbol(
                                                    "en-US",
                                                    transaction.currency
                                                  )}
                                                  {!!transaction.discountApplied
                                                    ? transaction.discountedPrice
                                                    : transaction.amount}
                                                </td>
                                                <td className="py-3 mb-0 text-center">
                                                  {getCurrencySymbol(
                                                    "en-US",
                                                    transaction.currency
                                                  )}
                                                  {!!transaction.discountApplied
                                                    ? transaction.discountedPrice
                                                    : transaction.amount}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                        <tfoot>
                                          <tr>
                                            <td>
                                              <h6 className="mb-0 font-weight-bold py-2 mb-3">
                                                Additional Information :
                                              </h6>
                                              <p>
                                                Your purchase will appear on
                                                your credit card statement as:
                                                Notary Ventures or
                                                Notaryweb3.com
                                              </p>
                                            </td>
                                            <td>
                                              <p className="mb-4 text-light text-center">
                                                {" "}
                                                Subtotal{" "}
                                              </p>
                                              {/* <p className="text-light text-center">
                                                {" "}
                                                Tax (18%){" "}
                                              </p> */}
                                            </td>
                                            <td>
                                              <p className="mb-4 text-light text-center">
                                                {getCurrencySymbol(
                                                  "en-US",
                                                  transactionResult[0]?.currency
                                                ) + totalAmount}
                                              </p>
                                              {/* <p className="text-light text-center">
                                                {" "}
                                                $99.98{" "}
                                              </p> */}
                                            </td>
                                          </tr>
                                          {transactionResult[0]
                                            ?.discountApplied && (
                                            <tr>
                                              <td />
                                              <td className="border-top">
                                                <h6 className="mb-0 font-weight-bold text-nowrap">
                                                  Discount Applied?
                                                </h6>
                                              </td>
                                              <td className="border-top">
                                                <p className="text-light mb-0 text-center">
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                  />
                                                </p>
                                              </td>
                                            </tr>
                                          )}

                                          <tr>
                                            <td />
                                            <td className="border-top">
                                              <h6 className="mb-0 font-weight-bold text-nowrap">
                                                Grand Total:
                                              </h6>
                                            </td>
                                            <td className="border-top">
                                              <p className="text-light mb-0 text-center">
                                                {getCurrencySymbol(
                                                  "en-US",
                                                  transactionResult[0]?.currency
                                                ) + totalAmount}
                                              </p>
                                            </td>
                                          </tr>
                                        </tfoot>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="receipt-foot p-sm-4 p-3">
                                    <div className="text-center py-3">
                                      <button
                                        onClick={handlePrint}
                                        className="btn btn-primary times px-4"
                                      >
                                        <i className="fa fa-print" /> Print
                                      </button>
                                      {/* <button className="btn btn-secondary times px-4">
                                  <i className="fa fa-download" /> Downlaod
                                </button> */}
                                    </div>
                                    <div className="py-3 text-center">
                                      <p className="m-0 text-light">
                                        <b className="text-white">NOTE :</b>{" "}
                                        this is computer generated receipt and
                                        does not require physical signature.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                            {!isLoading && transactionResult.length == 0 && (
                              <Badge className="bg-danger text-white">
                                {transactionID} NOT FOUND
                              </Badge>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
