import { useAuth0 } from "@auth0/auth0-react";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClearCookies } from "helpers/helper";
import storage from "helpers/storage";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect, register } = useAuth0();

  storage.deleteItem("Profile");
  return (
    <>
      <div>
        <a
          href=""
          className="btn btn-primary btn-sm-slim text-uppercase border border-white btn-border-primary"
          onClick={() => loginWithRedirect()}
        >
          {/* <img className="google-icon" src="/images/google-logo.svg" /> */}
          <FontAwesomeIcon className="icon-fontawesome-fix" icon={faSignIn} />
          Login
        </a>{" "}
        <a
          href=""
          onClick={() => {
            ClearCookies();
            loginWithRedirect({ screen_hint: "signup" });
          }}
          className="btn btn-primary btn-sm-slim text-uppercase btn-border-primary"
        >
          Signup
        </a>
      </div>
    </>
  );
};
export default LoginButton;
