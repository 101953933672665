export const API_SearchDomain = async (domain) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/domaindirectory/search?search=` +
      domain,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  return await res.json();
};
