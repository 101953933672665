export const API_GetMyCart = async (user) => {
  // convert into DTO
  const userDto = {
    name: user.name,
    email: user.email,
    sid: user.sub,
  };
  var body = JSON.stringify(userDto);
  const res = await fetch(`${process.env.REACT_APP_API_URL}/api/users/check`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body,
  });
  return await res.json();
};
export const API_RemoveItemFromCart = async (transactionID) => {
  //debugger;
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/cart/remove/${transactionID}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  return await res.json();
};
