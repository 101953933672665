import "styles/scss/style.scss";
import "styles/scss/main-common.scss";
import "react-toastify/dist/ReactToastify.css";
import HeaderLayout from "./components/layouts/HeaderLayout";
import FooterLayout from "components/layouts/FooterLayout";
import { Provider } from "react-redux";
import store from "store";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "pages/Home";
import Profile from "pages/profile";
import { ToastContainer } from "react-toastify";
import Success from "pages/checkout/Success";
import Cancel from "pages/checkout/Cancel";
import ErrorPage from "pages/Error";
import About from "pages/about";
import FAQs from "pages/faqs";
import SearchResultPage from "components/search/SearchResultPage";
import PrivacyPolicy from "pages/privacy-policy";
import TermsOfUse from "pages/terms-of-use";
import ContactUs from "pages/contact-us";
import NextSteps from "pages/checkout/NextSteps";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <HeaderLayout />
          <Home />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/profile",
      element: (
        <>
          <HeaderLayout />
          <Profile />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/search",
      element: (
        <>
          <HeaderLayout />
          <SearchResultPage />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/checkout/success",
      element: (
        <>
          <HeaderLayout />
          <Success />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/contact-us",
      element: (
        <>
          <HeaderLayout />
          <ContactUs />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/checkout/cancel",
      element: (
        <>
          <HeaderLayout />
          <Cancel />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/checkout/next-steps",
      element: (
        <>
          <HeaderLayout />
          <NextSteps />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/about-us",
      element: (
        <>
          <HeaderLayout />
          <About />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <>
          <HeaderLayout />
          <PrivacyPolicy />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/terms-of-use",
      element: (
        <>
          <HeaderLayout />
          <TermsOfUse />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "/faqs",
      element: (
        <>
          <HeaderLayout />
          <FAQs />
          <FooterLayout />
        </>
      ),
    },
    {
      path: "*",
      element: (
        <>
          <HeaderLayout />
          <ErrorPage />
          <FooterLayout />
        </>
      ),
    },
  ]);
  return (
    <Provider store={store}>
      <ToastContainer />
      <RouterProvider router={router}></RouterProvider>
    </Provider>
  );
}

export default App;
