import UserProfileCard from "components/profile/UserProfileCard";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ProfileDetails from "components/profile/ProfileDetails";
import WalletDetails from "components/profile/WalletDetails";
import MyOrders from "components/profile/MyOrders";
import { API_GetUserProfileBySID } from "api-services/User/GetUserProfile";
import { updateDBUser } from "slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_UpdateUserProfile } from "api-services/User/UpdateUserProfile";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AdminPanel from "components/profile/AdminPanel";
function Profile() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const dispatch = useDispatch();
  const dbUser = useSelector((state) => state.user.dbUser);
  const [userProfile, setUserProfile] = useState({});
  const [profileLoader, setProfileLoader] = useState(false);
  const [walletLoader, setWalletLoader] = useState(false);
  const navigate = useNavigate();
  // on pageload fetch data for user and fill the textboxes
  useEffect(() => {
    console.log("USER PROFILE:", user);
    if (user?.sub) {
      //debugger;
      const fetchUser = async () => {
        let userObj = await API_GetUserProfileBySID(user.sub);

        //debugger;
        if (userObj?.success) {
          userObj = userObj?.result;
          dispatch(updateDBUser(userObj));
          setUserProfile(userObj);
        }
      };
      fetchUser();
    }
  }, [user]);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!isAuthenticated) {
    navigate("/");
    return <></>;
  }

  const handleProfileSave = async () => {
    setProfileLoader(true);
    const update = await API_UpdateUserProfile(dbUser);

    if (update?.success) {
      toast("Your profile has been updated...", {
        // hideProgressBar: true,
        autoClose: 3000,
        type: "success",
      });
      setProfileLoader(false);
    }
  };
  const handleWalletAddressSave = async () => {
    setWalletLoader(true);
    const update = await API_UpdateUserProfile(dbUser);

    if (update?.success) {
      toast("Your cryto wallet address has been updated...", {
        autoClose: 3000,
        type: "success",
      });
      setWalletLoader(false);
    }
  };
  console.log(
    "ISADMIN ",
    process.env.REACT_APP_ADMIN_EMAILS?.indexOf(user.email) !== -1
  );
  return (
    <>
      <div className="contain-box">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container-fluid">
              {/* Navigation START */}

              <div className="container-fluid">
                <div className="row">
                  <div className="container-fluid mt-5 table-orders">
                    {user && <UserProfileCard user={user} />}

                    <div className="row">
                      {/* https://bootsnipp.com/snippets/QMWmj */}
                      <div className="col-sm-12">
                        <div className="card text-center">
                          <div className="card-header">
                            <ul
                              className="nav nav-tabs nav-fill card-header-tabs"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  id="home-tab"
                                  data-toggle="tab"
                                  href="#home"
                                  role="tab"
                                  aria-controls="home"
                                  aria-selected="true"
                                >
                                  <i className="fas fa-edit" /> Profile
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="history-order-tab"
                                  data-toggle="tab"
                                  href="#history-order"
                                  role="tab"
                                  aria-controls="profile"
                                  aria-selected="false"
                                >
                                  <i className="fas fa-shopping-bag" /> Wallet
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="bonus-list-tab"
                                  data-toggle="tab"
                                  href="#bonus-list"
                                  role="tab"
                                  aria-controls="contact"
                                  aria-selected="false"
                                >
                                  <i className="fab fa-bootstrap" /> Orders
                                </a>
                              </li>
                              {process.env.REACT_APP_ADMIN_EMAILS?.indexOf(
                                user.email
                              ) !== -1 && (
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    id="Admin_Panel_tab"
                                    data-toggle="tab"
                                    href="#Admin_Panel"
                                    role="tab"
                                    aria-controls="contact"
                                    aria-selected="false"
                                  >
                                    <i className="fab fa-bootstrap" /> Admin
                                    Panel
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                          <div
                            className="tab-content card-body profile-tab-fix-height"
                            id="myTabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <div className="row">
                                {user && userProfile?.name && (
                                  <>
                                    <ProfileDetails userProfile={userProfile} />
                                    <div className="col-sm-12 text-center text-sm-right">
                                      <button
                                        className="btn btn-primary "
                                        type="submit"
                                        onClick={handleProfileSave}
                                      >
                                        {profileLoader && (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        )}{" "}
                                        Save Profile
                                      </button>
                                    </div>
                                  </>
                                )}
                                {user && !userProfile?.name && (
                                  <div className="status">
                                    <div className="shape shape-1"></div>
                                    <div className="shape shape-2"></div>
                                    <div className="shape shape-3"></div>
                                    <div className="shape shape-4"></div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="history-order"
                              role="tabpanel"
                              aria-labelledby="history-order-tab"
                            >
                              <div className="row">
                                {user && !userProfile?.name && (
                                  <div className="status">
                                    <div className="shape shape-1"></div>
                                    <div className="shape shape-2"></div>
                                    <div className="shape shape-3"></div>
                                    <div className="shape shape-4"></div>
                                  </div>
                                )}
                                {user && userProfile?.name && (
                                  <>
                                    <WalletDetails />
                                    <div className="col-sm-12 text-center text-sm-right">
                                      <button
                                        className="btn btn-primary "
                                        type="submit"
                                        onClick={handleWalletAddressSave}
                                      >
                                        {walletLoader && (
                                          <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                        )}{" "}
                                        Save Wallet Address
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="bonus-list"
                              role="tabpanel"
                              aria-labelledby="bonus-list-tab"
                            >
                              <MyOrders />
                            </div>
                            {process.env.REACT_APP_ADMIN_EMAILS?.indexOf(
                              user.email
                            ) !== -1 && (
                              <div
                                className="tab-pane fade"
                                id="Admin_Panel"
                                role="tabpanel"
                                aria-labelledby="Admin_Panel"
                              >
                                <AdminPanel />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FORM END */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;
//export const getServerSideProps = withPageAuthRequired();
