import { updateDBUser } from "slices/userSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";

const ProfileDetails = ({ userProfile }) => {
  const dbUser = useSelector((state) => state.user.dbUser);

  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const handleChange = (e, entityName) => {
    dispatch(updateDBUser({ ...dbUser, ...{ [entityName]: e.target.value } }));
  };
  const handleDropdownChange = (dropdownValue, entityName) => {
    debugger;
    setSelected(dropdownValue);
    dispatch(updateDBUser({ ...dbUser, ...{ [entityName]: dropdownValue } }));
  };

  useEffect(() => {
    setSelected(dbUser?.billing_Country);
  }, [dbUser]);
  if (!userProfile && !dbUser) {
    return <></>;
  }
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  return (
    <>
      {dbUser?.name && (
        <>
          <div className="col-sm-6">
            <form>
              <div className="form-row">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Name
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={dbUser?.name || ""}
                    placeholder="Name"
                    onChange={(e) => handleChange(e, "name")}
                  />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Email
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={dbUser?.email || ""}
                    placeholder="Email"
                    disabled
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                      Phone
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={dbUser?.phone || ""}
                    onChange={(e) => handleChange(e, "phone")}
                    placeholder="+1 123 1234 1234"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-sm-6">
            <div className="form-row">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    Billing Name
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={dbUser?.billing_Name || ""}
                  onChange={(e) => handleChange(e, "billing_Name")}
                  placeholder="Billing Name..."
                />
              </div>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    Billing Email
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={dbUser?.billing_Email || ""}
                  onChange={(e) => handleChange(e, "billing_Email")}
                  placeholder="Billing Email..."
                />
              </div>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon3">
                    United State: Select State
                  </span>
                </div>
                {/* <ReactFlagsSelect
                  className="form-control country-dropdown"
                  selected={selected}
                  placeholder="Select Country"
                  searchable
                  onSelect={(code) =>
                    handleDropdownChange(code, "billing_Country")
                  }
                /> */}

                <Form.Control
                  className="form-control "
                  as="select"
                  value={dbUser?.billing_Country || ""}
                  onChange={(e) =>
                    handleDropdownChange(e.target.value, "billing_Country")
                  }
                >
                  {states.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ProfileDetails;
