import { FaqData } from "helpers/faq-data";
import { useEffect } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQs = () => {
  useEffect(() => {
    let script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/jquery.isotope/3.0.6/isotope.pkgd.min.js";
    document.getElementsByTagName("body")[0].append(script);
    setTimeout(() => {
      var $ = window.jQuery;
      let faq = $(".faq");
      if (faq) {
        $(function () {
          // $(".faq .grid").masonry({ itemSelector: ".grid-item" });

          $(".filtering").on("click", "span", function () {
            var a = $(".gallery").isotope({});
            var e = $(this).attr("data-filter");
            a.isotope({ filter: e });
          });
          $(".filtering").on("click", "span", function () {
            $(this).addClass("active").siblings().removeClass("active");
          });

          let length = document.querySelectorAll(".gallery .grid-item").length;
          let i = length;
          $(".gallery .grid-item").each(function () {
            $(this).css({
              "z-index": i,
            });
            i--;
          });
        });
      }
    }, 3000);
  }, []);
  return (
    <>
      <div className="contain-box">
        <div className="home-center">
          <div className="home-desc-center">
            <div
              className="container"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <section className="section-bg faq">
                <div className="container">
                  <div className="pb-3 sec-head">
                    <h1 className="sec-title"> FAQ </h1>
                    <p className="text-center">
                      {" "}
                      Frequently Asked Questions - we've got you covered! <br />
                      Everything you need to know, all in one place. If the FAQs
                      below don't answer your questions, Ask our Notary Bot!
                    </p>
                  </div>

                  <div className="row no-gutters">
                    <div className="filtering col-sm-12 text-center mb-5">
                      <div className="filtering-inner d-inline-block py-2 px-md-5 px-2">
                        <span
                          data-filter="*"
                          className="my-md-2 my-1 active pl-0"
                        >
                          General
                        </span>
                        <span data-filter=".faq-web3" className="my-md-2 my-1 ">
                          Web3
                        </span>
                        <span
                          data-filter=".faq-purchases"
                          className="my-md-2 my-1 "
                        >
                          Payments & Purchases
                        </span>
                      </div>
                    </div>
                    <div className="col-12 text-center w-100">
                      <div className="grid form-row gallery text-center">
                        {FaqData?.map((item, index) => (
                          <div
                            key={index}
                            className={`col-lg-4 col-sm-6 mb-2 grid-item ${item.catagory}`}
                          >
                            <div className="card px-2">
                              <div className="px-0 card-header">
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item>
                                    <Accordion.Header>
                                      {item.question}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div
                                        className="accordian-content"
                                        style={{ textAlign: "left" }}
                                        dangerouslySetInnerHTML={{
                                          __html: item.answer,
                                        }}
                                      ></div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="pt-5 my-0 mx-auto col-lg-4 col-md-6 col-sm-8">
                      <p className="text-center">
                        {" "}
                        If you can't find the answer to your question, please
                        don't hesitate to{" "}
                        <Link
                          className="link gold fw-semibold text-underline"
                          to="/contact-us"
                        >
                          contact{" "}
                        </Link>
                        us directly.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQs;
