const { useState, useEffect } = require("react");
window.currentLoopIndex = 0;
window.xTypingText = "";
const DynamicTypeBox = ({ suggestions, onChange }) => {
  const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
  const [typingText, setTypingText] = useState("");

  useEffect(() => {
    typingEffect(currentSuggestionIndex);
  }, []);
  const typingEffect = () => {
    debugger;
    // if (index) {
    //   return false;
    // }

    const currentSuggestion = suggestions[window.currentLoopIndex];
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentSuggestion.length === currentIndex) {
        setCurrentSuggestionIndex((prevState) => prevState++);
        removeTypingEffect();
        // remove Typing
        clearInterval(typingInterval);
      } else {
        // window.xTypingText(
        //   (prevState) => prevState + "" + currentSuggestion[currentIndex]
        // );
        window.xTypingText =
          window.xTypingText + currentSuggestion[currentIndex];
        setTypingText(window.xTypingText + "|");
        console.log("Character::", currentSuggestion[currentIndex]);
        currentIndex++;
      }
    }, 300);
  };
  const removeTypingEffect = () => {
    const currentSuggestion = suggestions[window.currentLoopIndex];
    let currentIndex = currentSuggestion.length - 1;

    const removetypingInterval = setInterval(() => {
      if (currentIndex === -1) {
        window.currentLoopIndex = window.currentLoopIndex + 1;
        if (window.currentLoopIndex === suggestions.length) {
          window.currentLoopIndex = 0;
        }
        typingEffect(window.currentLoopIndex);
        // remove Typing
        clearInterval(removetypingInterval);
      } else {
        window.xTypingText =
          window.xTypingText.substring(0, currentIndex) + "|";
        if (window.xTypingText === "|") {
          window.xTypingText = "";
        }
        setTypingText(window.xTypingText);

        currentIndex--;
      }
    }, 150);
  };
  return (
    <input
      type="text"
      className="form-control form-control-lg rounded-0"
      placeholder={typingText}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
export default DynamicTypeBox;
