export const API_TransactionSuccess = async (transaction) => {
  const { Id, type } = transaction;
  var apiUrl = process.env.REACT_APP_API_URL;
  //debugger;
  const res = await fetch(`${apiUrl}/api/transactions/checkout/${Id}/${type}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  return await res.json();
};
