import { TransactionStatus } from "helpers/enum";

export const Checkout = async (myCart, user, billingName) => {
  //debugger;
  // convert into DTO
  var checkOutObj = [];
  myCart.forEach((element) => {
    // only Add to cart items should be checkout
    if (element.status == TransactionStatus.AddToCart) {
      checkOutObj.push({
        domainCost: element.amount,
        currency: element.currency,
        domainName: element.itemName,
        userSid: user.sub,
        userFullName: user.billingName + " | " + user.email,
        cryptoWalletAddress: element.walletAddress,
      });
    }
  });
  var body = JSON.stringify({
    checkOutData: checkOutObj,
    billingName: billingName,
  });
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/payment/checkout`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body,
    }
  );
  return await res.json();
};
