import React, { useEffect, useState } from "react";

export default function ZoomPicture({ url }) {
  const [display, setDisplay] = useState("none");
  useEffect(() => {
    setTimeout(() => {
      setDisplay("block");
    }, Math.floor(Math.random() * (10000 - 1000 + 1) + 1000));
  }, []);
  return (
    <div style={{ display: display }} className="zoom-in-out-box">
      <img src={url} style={{ width: 50 }} />
    </div>
  );
}
